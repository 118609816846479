<template>
  <div class="accounts-summary">
    !! NOT IMPLEMENTED !!

    <accounts-aging />
    <accounts-collection-segments />
    <accounts-work-queues />
    <accounts-strategy />
    <accounts-spark />
  </div>
</template>

<script>

export default {
  components: {
    AccountsAging: () => import('./accounts-aging'),
    AccountsCollectionSegments: () => import('./accounts-collection-segments'),
    AccountsSpark: () => import('./accounts-spark'),
    AccountsStrategy: () => import('./accounts-strategy'),
    AccountsWorkQueues: () => import('./accounts-work_queues')
  },
  activated () {
    this.$store.commit('setModule', { name: 'Accounts' })
  }
}
</script>
<style lang="stylus" scoped>
.accounts-summary
  display flex
  overflow auto
  flex-grow 1
  flex-wrap wrap
  padding 0.5em
  align-content flex-start

.accounts-summary>*
  margin 0.5em
</style>
